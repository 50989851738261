import React from 'react'
import { graphql } from 'gatsby'
import BlockHero from 'components/blocks/BlockHero'
import BlockTextImagePost from 'components/blocks/BlockTextImagePost'
import BlockCards from 'components/blocks/BlockCards'
import BlockContact from 'components/blocks/BlockContact'
import Footer from 'components/Footer'
import Seo from 'components/Seo'

const Page = ({ data }) => {
  const merchants = data.allPrismicMerchant.nodes
  const thisMerchant = data.prismicMerchant
  const processExcerpt = thisMerchant.data.banner_subtitle.text.replace(
    '|',
    '&nbsp;&nbsp;|&nbsp;&nbsp;'
  )
  const moreMerchants = merchants.filter(
    (merchant) => merchant.uid !== thisMerchant.uid
  )
  const content = {
    blockHero: {
      gatsbyImage: thisMerchant.data.banner_image,
      video: thisMerchant.data.banner_video.text,
      textBlock: {
        isDark: true,
        text: {
          pretitle: '品牌页面 ',
          title: `<span class='color--accent'>${thisMerchant.data.banner_title_1.text}</span>
          ${thisMerchant.data.banner_title_2.text}`,
          description: `<span class='color--accent'>${processExcerpt}</span>`,
        },
      },
      hasReturnButton: true,
    },
    postBlocks: thisMerchant.data.body.map((block) => {
      return {
        gatsbyImage: block.primary.block_image,
        text: {
          title: block.primary.block_title.text,
          subtitle: block.primary.block_subtitle.text,
          body: block.primary.block_body,
        },
      }
    }),
    cards: {
      title: '更多品牌',
      cards: moreMerchants.map((merchant) => {
        return {
          link: `/merchants/${merchant.uid}/`,
          gatsbyImage: merchant.data.image,
          text: {
            pretitle: '品牌页面',
            title: merchant.data.title.text,
            excerpt: merchant.data.excerpt.text,
          },
        }
      }),
    },
    blockHero2: {
      image: `image--taco-01-3e0985bda48185867e4a0e47a0d0176b.jpg`,
      textBlock: {
        isDark: true,
        text: {
          title: `锁定
          <span>优惠名额</span>`,
          description: `即刻预约.`,
        },
        hasContactButton: true,
      },
    },
  }
  let imageCount = 0
  let postBlocks = content.postBlocks.map((block, i) => {
    let setImagePos = null
    if (block.gatsbyImage.localFile) {
      imageCount++
      setImagePos = imageCount % 2 === 0 ? 'left' : 'right'
    }
    return {
      gatsbyImage: block.gatsbyImage.localFile ? block.gatsbyImage : null,
      imagePos: setImagePos,
      text: block.text,
      bg: i % 2 === 0 ? 'grey-2' : 'primary',
    }
  })
  const seoContent = {
    title: `星选加盟 - ${thisMerchant.data.title.text}`,
  }

  return (
    <div>
      <Seo content={seoContent} />
      <BlockHero
        content={content.blockHero}
        mobileBackgroundClass='blue-to-grey'
      />
      {postBlocks.map((block, i) => (
        <BlockTextImagePost key={i} content={block} />
      ))}
      <BlockHero
        content={content.blockHero2}
        mobileBackgroundClass='blue-to-grey'
        bannerShorter={true}
      />
      <BlockCards content={content.cards} />
      <BlockContact />
      <Footer />
    </div>
  )
}

export default Page

export const query = graphql`
  query ($uid: String!) {
    prismicMerchant(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        banner_image {
          gatsbyImageData
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        banner_video {
          text
        }
        banner_title_1 {
          text
        }
        banner_title_2 {
          text
        }
        banner_subtitle {
          text
        }
        body {
          ... on PrismicMerchantDataBodyTextImageBlock {
            id
            primary {
              block_title {
                text
              }
              block_subtitle {
                text
              }
              block_body {
                richText
              }
              block_image {
                gatsbyImageData
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicMerchant(sort: { order: DESC, fields: last_publication_date }) {
      nodes {
        uid
        data {
          title {
            text
          }
          excerpt {
            text
          }
          image {
            gatsbyImageData
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
